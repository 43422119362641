<template>
  <ion-page>
    <Header
      title="Informações do jogador"
      showBackButton
      @onClick="onClose()"
    />

    <Loading v-if="loading" />
    <ion-content :fullscreen="true">
      <div v-if="!loading">
        <div class="banner"></div>

        <div>
          <Avatar class="avatar" width="90" height="90" :imageUrl="imageUrl" />
        </div>

        <div
          style="display: flex; justify-content: space-between; width: 90%; margin: auto;"
        >
          <ion-card
            style="width: 47%; background-color: #f3f3f3; margin: 0;"
            @click="setOpenModalTrophies()"
          >
            <ion-card-header
              class="ion-justify-content-between ion-align-items-center"
            >
              <ion-card-title
                >Troféus
                <span :style="amountTrophies > 0 ? 'color: #2dd36f;' : ''"
                  >({{ amountTrophies }})</span
                ></ion-card-title
              >
            </ion-card-header>
            <ion-card-content
              style="display: flex; justify-content: center; align-items: center;"
            >
              <div style="display: flex;">
                .....
                <ion-icon
                  style="font-size: 30px; "
                  :icon="trophySharp"
                  color="warning"
                />
                <ion-icon
                  style="font-size: 30px;"
                  :icon="trophySharp"
                  color="warning"
                />
                .....
              </div>
            </ion-card-content>
          </ion-card>

          <ion-card
            style="width: 47%; background-color: #f3f3f3; margin: 0;"
            @click="setOpenModalAchievements()"
          >
            <ion-card-header
              class="ion-justify-content-between ion-align-items-center"
            >
              <ion-card-title>Conquistas</ion-card-title>
            </ion-card-header>
            <ion-card-content
              style="display: flex; justify-content: center; align-items: center;"
            >
              <div style="display: flex;">
                .....
                <ion-icon
                  style="font-size: 30px; "
                  :icon="starSharp"
                  color="warning"
                />
                <ion-icon
                  style="font-size: 30px;"
                  :icon="medalSharp"
                  color="warning"
                />
                .....
              </div>
            </ion-card-content>
          </ion-card>
        </div>

        <ion-card v-if="ultimoAcesso && accessLevel < 3">
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="time" />
              <ion-card-title>Último acesso</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>
              {{ ultimoAcesso }}
            </h1>
          </ion-card-content>
        </ion-card>

        <ion-card v-if="accessLevel < 3">
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="medical" />
              <ion-card-title>E-mail</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>
              {{ email }}
            </h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="person" />
              <ion-card-title>Nome completo</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>
              {{ name }}
            </h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
          >
            <div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="at" />

              <ion-card-title>Apelido</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>
              {{ nickname }}
            </h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="documentText" />
              <ion-card-title>Descrição</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1 style="height: initial;">
              {{ description ? description : "Não informada" }}
            </h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="happy" />
              <ion-card-title>Idade</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>
              {{ ageC ? ageC : "Idade não informada" }}
            </h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="code" />
              <ion-card-title>Melhor pé</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>{{ bestFoot ? bestFoot : "Não informado" }}</h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="body" />
              <ion-card-title>Posição</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>{{ position ? position : "Não informada" }}</h1>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-header
            class="ion-justify-content-between ion-align-items-center"
            ><div
              style="display: flex;"
              class="ion-justify-content-start ion-align-items-center"
            >
              <ion-icon :color="theme" :icon="heart" />
              <ion-card-title>Time que torce</ion-card-title>
            </div>
          </ion-card-header>

          <ion-card-content>
            <h1>
              {{ cheeringTeam ? cheeringTeam : "Não informado" }}
            </h1></ion-card-content
          >
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import { calculateAge } from "@/helpers/Functions.js";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "PlayerProfile",
  mixins: [requestManager, computedManager],
  components: {
    IonContent,
    IonPage,
    Header,
    Avatar,
    Loading,
  },
  props: {
    playerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      nickname: "",
      description: "",
      imageUrl: "",
      age: "",
      bestFoot: "",
      position: "",
      ultimoAcesso: "",
      cheeringTeam: "",
      email: "",
      amountTrophies: 0,
    };
  },
  computed: {
    anotherPlayerProfile() {
      return this.$store.state.base.anotherPlayerProfile;
    },
    ageC: {
      get() {
        return calculateAge(...this.age.split("-"));
      },
      set(pValue) {
        this.age = pValue;
      },
    },
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removePropsModalCascate");
      this.$store.commit("base/removeShowModalCascate");
    });
    if (
      !this.anotherPlayerProfile ||
      !this.anotherPlayerProfile.cdId ||
      this.anotherPlayerProfile.cdId != this.playerId
    ) {
      await this.init();
    }

    this.getAmountTrophies();

    this.name = this.anotherPlayerProfile.nome;
    this.nickname = this.anotherPlayerProfile.apelido;
    this.description = this.anotherPlayerProfile.descricao;
    this.imageUrl = this.anotherPlayerProfile.imagemUrl;
    this.age = this.anotherPlayerProfile.dataNascimento;
    this.bestFoot = this.anotherPlayerProfile.melhorPe;
    this.position = this.anotherPlayerProfile.posicao;
    this.ultimoAcesso = this.anotherPlayerProfile.ultimoAcesso;
    this.cheeringTeam = this.anotherPlayerProfile.timeQueTorce;
    this.email = this.anotherPlayerProfile.email;
  },
  methods: {
    onClose() {
      this.$store.commit("base/removePropsModal", "ModalPlayerProfile");
      this.$store.commit("base/removeShowModal", "ModalPlayerProfile");
    },
    async getAmountTrophies() {
      this.loading = true;
      const response = await this.genericRequest("common/getAmountTrophies", {
        playerId: this.playerId,
      });
      if (typeof response != "string") {
        this.amountTrophies = response;
      } else {
        this.amountTrophies = 0;
      }
      this.loading = false;
    },
    setOpenModalTrophies() {
      if (this.amountTrophies > 0) {
        this.$store.commit("base/setAllMatchesTrophies", []);
        this.$store.commit("base/addPropsModal", {
          name: "ModalTrophies",
          prop: this.playerId,
        });

        this.$store.commit("base/addShowModal", "ModalTrophies");
      }
    },
    setOpenModalAchievements() {
      this.$store.commit("base/addShowModal", "ModalAchievements");
    },
    async init() {
      this.loading = true;
      const response = await this.genericRequest(
        "common/getProfileInformation",
        {
          playerId: this.playerId,
        }
      );
      this.$store.commit("base/setAnotherPlayerProfile", response || {});
      this.loading = false;
    },
  },
};
</script>
<style scoped>
h1 {
  margin: 0;
  height: 24px;
  padding-top: 2px;
  min-height: 24px;
}

ion-icon {
  font-size: 18px;
  margin-right: 10px;
}

ion-card-content {
  padding: 10px;
}
ion-card-content h1 {
  font-size: 16px;
  color: #959595;
}

.banner {
  background-color: #3880e6;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  width: 100%;
  overflow: hidden;
}

.avatar {
  margin: auto;
  margin-top: -45px;
  width: 90%;
  margin-bottom: 20px;
  position: relative;
  border-radius: 50%;
  border: 5px solid #ffffff;
}
</style>
