import { default as http } from "@/service/httpService.js";

const state = { playerImage: "", playerInformation: {} };

const getters = {
  getPlayerImage(state) {
    return state.playerImage;
  },
  getPlayerInformation(state) {
    return state.playerInformation;
  },
};

const mutations = {
  setPlayerImage(state, payload) {
    state.playerImage = payload;
  },
  setPlayerInformation(state, payload) {
    state.playerInformation = payload;
  },
};

const actions = {
  async getPlayerInformation(_, payload) {
    return http
      .post("perfil.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async saveProfile(_, payload) {
    return http
      .post("perfil-update.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async uploadImage(_, payload) {
    return http
      .post("upload-imagem.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
};

export const profile = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
