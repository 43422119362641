<template>
  <ion-page>
    <Header title="Criar nova partida" showBackButton @onClick="onClose()" />
    <Loading v-if="loading" />
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Opções</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content
          style="display: flex; justify-content: space-between; padding: 10px;"
        >
          <ion-button color="danger" @click="onClose()">cancelar</ion-button>
          <ion-button :disabled="amountPlayers" @click="createMatch()"
            >Criar partida</ion-button
          >
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          style="display: flex; justify-content: space-between; padding: 10px;"
        >
          <ion-card-subtitle>Tipo de partida</ion-card-subtitle>
          <ion-select
            class="ion-no-padding"
            :value="matchType"
            :disabled="loading"
            interface="popover"
            @ionChange="matchType = $event.target.value"
          >
            <ion-select-option value="N">Normal</ion-select-option>
            <ion-select-option value="C">Contra</ion-select-option>
          </ion-select>
        </ion-card-header>
        <ion-card-content>
          <ion-segment
            @ionChange="segmentChanged($event)"
            :value="selectedTeam"
          >
            <ion-segment-button
              value="teamOne"
              :style="getColor(theme)"
              selected
            >
              <ion-label>Time 1</ion-label>
            </ion-segment-button>
            <ion-segment-button value="teamTwo" :style="getColor(theme)">
              <ion-label>Time 2</ion-label>
            </ion-segment-button>
          </ion-segment>
          <ion-item v-if="selectedTeam == 'teamOne'" lines="none">
            <div
              style="display: flex; flex-wrap: wrap; padding-top: 10px; padding-bottom: 10px;"
            >
              <ion-chip
                v-for="item in playersSelectedTeam1.players"
                :key="item.cdId"
              >
                <Avatar
                  class="avatar"
                  width="35"
                  height="35"
                  :imageUrl="item.imagemUrl"
                  eventOn
                />
                <ion-label style="max-width: 200px;"
                  ><h2 class="nickname-color">{{ item.apelido }}</h2></ion-label
                >
              </ion-chip>
            </div>
          </ion-item>

          <ion-item v-if="selectedTeam == 'teamTwo'" lines="none">
            <div
              style="display: flex; flex-wrap: wrap; padding-top: 10px; padding-bottom: 10px;"
            >
              <ion-chip
                v-for="item in playersSelectedTeam2.players"
                :key="item.cdId"
              >
                <Avatar
                  class="avatar"
                  width="35"
                  height="35"
                  :imageUrl="item.imagemUrl"
                />
                <ion-label
                  ><h2 class="nickname-color">{{ item.apelido }}</h2></ion-label
                >
              </ion-chip>
            </div>
          </ion-item>
          <div
            v-if="
              (playersSelectedTeam1.players.length >= maximumPlayersPerTeam &&
                selectedTeam == 'teamOne') ||
                (playersSelectedTeam2.players.length >= maximumPlayersPerTeam &&
                  selectedTeam == 'teamTwo')
            "
            style="display: flex;"
          >
            <div
              class="actions-button"
              style="background-color: #eb445a;"
              @click="removeTeam()"
            >
              Remover
            </div>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Times</ion-card-subtitle>
        </ion-card-header>
        <ion-searchbar
          debounce="1000"
          class="nickname-color"
          placeholder="Buscar time"
          :value="searchValue"
          @ionChange="searchValue = $event.target.value"
        ></ion-searchbar>
        <ion-card-content>
          <ion-list>
            <div
              class="container-ranking"
              v-for="(team, index) in teamListLocalC.sort(
                (a, b) => a.sequence - b.sequence
              )"
              :key="index"
            >
              <ion-item lines="none" style="border-bottom: 1px solid #d9d9d9;">
                <div
                  style="display: flex; align-items: center; margin-right: 15px;"
                >
                  <div class="team" style="margin: 3px;">
                    <template
                      v-for="item in team.players.sort((a, b) =>
                        a.apelido > b.apelido ? 1 : -1
                      )"
                      :key="item.cdId"
                    >
                      <Avatar
                        class="avatar-list"
                        width="50"
                        height="50"
                        :imageUrl="item.imagemUrl"
                        eventOn
                        @onClick="setOpenModalPlayerProfile(item.cdId)"
                      />
                    </template>
                  </div>
                </div>
                <ion-label>
                  <h2
                    class="nickname-color"
                    :style="!team.teamName ? 'color: #ea4335 !important;' : ''"
                  >
                    {{ team.teamName ? team.teamName : "Não informado" }}
                  </h2>
                </ion-label>
                <ion-fab-button
                  :disabled="
                    (playersSelectedTeam1.players.length >=
                      maximumPlayersPerTeam &&
                      selectedTeam == 'teamOne') ||
                      (playersSelectedTeam2.players.length >=
                        maximumPlayersPerTeam &&
                        selectedTeam == 'teamTwo')
                  "
                  fill="clear"
                  class="ion-no-padding"
                  style="border: 1px solid #e9e9e9; border-radius: 50%;"
                  @click.stop="selectTeam(team.sequence)"
                  ><ion-icon :icon="arrowUp" color="success"></ion-icon
                ></ion-fab-button>
              </ion-item>
            </div>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="2000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "CreateMatch",
  mixins: [requestManager, computedManager],
  components: {
    Header,
    Avatar,
    IonToast,
    Loading,
  },
  data() {
    return {
      isOpenToast: false,
      message: "",
      isOpenModalPlayerProfile: false,
      maximumPlayersPerTeam: 3,
      selectedTeam: "teamOne",
      searchValue: "",
      matchType: "N",
      teamListLocal: [],
      playersSelectedTeam1: { players: [] },
      playersSelectedTeam2: { players: [] },
    };
  },
  computed: {
    teamList() {
      return this.$store.state.base.teamList;
    },
    teamListLocalC() {
      if (this.searchValue) {
        return this.teamListLocal.filter((e) => {
          return e.teamName
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        return this.teamListLocal;
      }
    },
    amountPlayers() {
      return (
        this.playersSelectedTeam1.players.length +
          this.playersSelectedTeam2.players.length !=
        this.maximumPlayersPerTeam * 2
      );
    },
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removeShowModalCascate");
    });

    this.$store.commit("base/setReloadCurrentMatch", 0);

    const teamList = JSON.parse(JSON.stringify(this.teamList));
    if (teamList.length > 0) this.teamListLocal = teamList;
  },
  methods: {
    onClose() {
      this.$store.commit("base/removeShowModal", "ModalCreateMatch");
    },
    selectTeam(pSequence) {
      const team = this.teamListLocal.find((e) => e.sequence == pSequence);
      if (this.selectedTeam == "teamOne" && team) {
        this.playersSelectedTeam1 = team;
      } else if (this.selectedTeam == "teamTwo" && team) {
        this.playersSelectedTeam2 = team;
      }

      this.teamListLocal = this.teamListLocal.filter(
        (e) => e.sequence != pSequence
      );
    },
    removeTeam() {
      if (this.selectedTeam == "teamOne") {
        this.teamListLocal = [...this.teamListLocal, this.playersSelectedTeam1];
        this.playersSelectedTeam1 = { players: [] };
      } else if (this.selectedTeam == "teamTwo") {
        this.teamListLocal = [...this.teamListLocal, this.playersSelectedTeam2];
        this.playersSelectedTeam2 = { players: [] };
      }
    },
    async createMatch() {
      const team1 = this.playersSelectedTeam1.players.map((e) => {
        return { cdIdPlayer: e.cdId, chosenTeam: 1 };
      });

      const team2 = this.playersSelectedTeam2.players.map((e) => {
        return { cdIdPlayer: e.cdId, chosenTeam: 2 };
      });

      this.loading = true;
      const response = await this.$store.dispatch("common/createMatch", {
        cdIdOwner: this.playerInformation.cdId,
        players: [...team1, ...team2],
        matchType: this.matchType,
        token: this.token,
      });

      if (response != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      } else {
        this.$store.commit("base/setLastWinner", []);
        this.$store.commit("base/setAllMatchesDay", []);
        this.$store.commit("base/setReloadCurrentMatch", 10);
        this.onClose();
      }
      this.loading = false;
    },
    getColor(pClassName) {
      if (pClassName == "primary") {
        return "color: #3880e6; --color-checked: #3880e6;";
      } else if (pClassName == "secondary") {
        return "color: #3dc2ff; --color-checked: #3dc2ff;";
      } else if (pClassName == "tertiary") {
        return "color: #5260ff; --color-checked: #5260ff;";
      } else if (pClassName == "success") {
        return "color: #2dd36f; --color-checked: #2dd36f;";
      } else if (pClassName == "warning") {
        return "color: #ffc409; --color-checked: #ffc409;";
      } else if (pClassName == "danger") {
        return "color: #eb445a; --color-checked: #eb445a;";
      }
    },
    segmentChanged(pEvent) {
      this.selectedTeam = pEvent.detail.value
        ? pEvent.detail.value
        : "selectedTeam";
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
  },
};
</script>
<style scoped>
h2 {
  margin: 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

p {
  margin: 0;
  font-size: 25px;
}

ion-label {
  font-size: 18px;
}

ion-fab-button {
  width: 30px;
  height: 30px;
}

.container-number-players {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
}
.container-number-players ion-icon {
  font-size: 30px;
  font-weight: bold;
}

ion-icon {
  font-size: 18px;
}

ion-searchbar {
  width: 100% !important;
  --background: white;
  --box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
}

ion-list {
  padding: 0;
}

ion-list ion-item {
  padding-top: 0px;
}

ion-chip {
  height: 35px;
  --background: rgb(240, 240, 240);
  border: 1px solid #d9d9d9;
}

ion-chip ion-avatar {
  width: 35px;
  height: 35px;
  margin-left: -12px;
}

ion-card-content {
  padding: 0;
}

ion-item {
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
  --highlight-height: none;
  width: 100%;
  display: flex;
}

ion-picker input {
  font-weight: bold;
}

.container-ranking {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
}

.container-ranking ion-icon {
  font-size: 25px;
}

.avatar {
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.avatar-list {
  border-radius: 50%;
  border: 3px solid white;
  margin-left: -25px;
  background-color: white;
}

.team {
  display: flex;
  justify-content: center;
  padding-left: 25px;
  flex-wrap: wrap;
}

.actions-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}
</style>
