import { default as http } from "@/service/httpService.js";

const state = {};

const getters = {};

const mutations = {
  setPlayer(state, payload) {
    state.player = payload;
  },
};

const actions = {
  async login(_, payload) {
    return http
      .post("login.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async register(_, payload) {
    return http
      .post("cadastra-jogador.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async recoverPassword(_, payload) {
    return http
      .post("recupera-senha.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
};

export const login = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
