<template>
  <ion-page>
    <Header
      title="Gerenciamento de partida"
      showBackButton
      @onClick="onClose()"
    />
    <Loading v-if="loading" />

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Opções</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content
          style="display: flex; justify-content: space-between; padding: 10px;"
        >
          <ion-button color="danger" @click="onClose()">cancelar</ion-button>
          <ion-button
            v-if="!substitutionMode"
            :disabled="!selectedPlayerId"
            @click="account()"
            >Contabilizar</ion-button
          >
          <ion-button
            v-else
            :disabled="!selectedPlayerId || !newPlayerId"
            @click="replace()"
            >Substituir</ion-button
          >
        </ion-card-content>
      </ion-card>

      <ion-card class="custom-ion-card" v-if="!substitutionMode">
        <ion-card-header>
          <ion-card-subtitle>Selecione a métrica</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-select
            class="ion-no-padding"
            :value="event"
            interface="popover"
            @ionChange="event = $event.target.value"
          >
            <ion-select-option value="4">Gol</ion-select-option>
            <ion-select-option value="5">Assitência</ion-select-option>
            <ion-select-option value="6">Gol de pênalti</ion-select-option>
            <ion-select-option value="9">Gol contra</ion-select-option>
          </ion-select>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-segment
            @ionChange="segmentChanged($event)"
            :value="selectedTeam"
          >
            <ion-segment-button
              value="teamOne"
              :style="getColor(theme)"
              selected
            >
              <ion-label>Time 1</ion-label>
            </ion-segment-button>
            <ion-segment-button value="teamTwo" :style="getColor(theme)">
              <ion-label>Time 2</ion-label>
            </ion-segment-button>
          </ion-segment>
          <template v-if="selectedTeam == 'teamOne'">
            <template
              v-for="item in matchSummary.playersTeamOne"
              :key="item.cdId"
            >
              <ion-item
                style="disply: flex; border-bottom: 1px solid #d9d9d9;"
                lines="none"
                @click="selectPlayer(item.cdId)"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                />
                <ion-label class="custom-ion-label">
                  <h2 class="nickname-color">{{ item.apelido }}</h2>
                </ion-label>
                <ion-icon
                  v-if="item.cdId == selectedPlayerId"
                  :icon="substitutionMode ? arrowDown : checkmarkCircle"
                  :color="substitutionMode ? 'danger' : 'success'"
                ></ion-icon>
              </ion-item>
            </template>
          </template>
          <template v-if="selectedTeam == 'teamTwo'">
            <template
              v-for="item in matchSummary.playersTeamTwo"
              :key="item.cdId"
            >
              <ion-item
                style="disply: flex; border-bottom: 1px solid #d9d9d9;"
                lines="none"
                @click="selectPlayer(item.cdId)"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                />
                <ion-label class="custom-ion-label">
                  <h2 class="nickname-color">{{ item.apelido }}</h2>
                </ion-label>
                <ion-icon
                  v-if="item.cdId == selectedPlayerId"
                  :icon="substitutionMode ? arrowDown : checkmarkCircle"
                  :color="substitutionMode ? 'danger' : 'success'"
                ></ion-icon>
              </ion-item>
            </template>
          </template>
          <div
            v-if="!substitutionMode && !matchSummary.changed"
            class="more-information"
            style="background-color: #3880e6;"
            @click="substitution()"
          >
            Substituir jogador
          </div>
          <div
            v-if="substitutionMode && !matchSummary.changed"
            class="more-information"
            style="background-color: #eb445a;"
            @click="substitution()"
          >
            Cancelar
          </div>
          <ion-searchbar
            v-if="substitutionMode"
            class="nickname-color"
            style="background: white;"
            debounce="1000"
            placeholder="Buscar jogador"
            :value="searchValue"
            @ionChange="searchValue = $event.target.value"
          ></ion-searchbar>
          <ion-list
            style="padding-top: 0; padding-bottom: 0;"
            v-if="substitutionMode"
          >
            <div
              class="container-administration"
              v-for="item in allConfirmedPlayersC.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <ion-item
                lines="none"
                style="disply: flex; border-bottom: 1px solid #d9d9d9; background: white;"
                @click="selectNewPlayer(item.cdId)"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                />
                <ion-label class="custom-ion-label">
                  <h2 class="nickname-color">{{ item.apelido }}</h2>
                </ion-label>

                <ion-icon
                  v-if="item.cdId == newPlayerId"
                  :icon="arrowUp"
                  color="success"
                ></ion-icon>
              </ion-item>
            </div>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-card class="custom-ion-card" v-if="!substitutionMode">
        <ion-card-header>
          <ion-card-subtitle>Escreva uma decrição (Opcional)</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content class="custom-textarea">
          <ion-textarea
            class="ion-no-padding"
            autoGrow
            placeholder="Digite uma descrição..."
            :value="description"
            @ionChange="description = $event.target.value"
          ></ion-textarea>
        </ion-card-content>
      </ion-card>

      <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="2000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "MatchManager",
  mixins: [requestManager, computedManager],
  components: {
    IonPage,
    IonContent,
    IonToast,
    Header,
    Avatar,
    Loading,
  },
  props: {
    matchSummary: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPlayerId: null,
      selectedTeam: "teamOne",
      event: "4",
      description: null,
      /* Refente ao modo de substituição */
      searchValue: "",
      message: "",
      newPlayerId: null,
      playersList: [],
      substitutionMode: false,
      isOpenToast: false,
    };
  },
  computed: {
    allConfirmedPlayers() {
      return this.$store.state.base.allConfirmedPlayers.filter(
        (e) => !this.playersPlayingId.includes(e.cdId)
      );
    },
    allConfirmedPlayersC() {
      if (this.searchValue) {
        return this.playersList.filter((p) => {
          return `${p.nome}${p.apelido}`
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        return this.playersList;
      }
    },
    alreadySelectedPlayerIds() {
      return this.$store.state.base.teamList
        .map((p) => p.players)
        .flat()
        .map((e) => e.cdId);
    },
    teamOne() {
      if (this.matchSummary?.playersTeamOne) {
        return this.matchSummary.playersTeamOne;
      }
      return [];
    },
    teamTwo() {
      if (this.matchSummary?.playersTeamTwo) {
        return this.matchSummary.playersTeamTwo;
      }
      return [];
    },
    playersPlaying() {
      return [...this.teamOne, ...this.teamTwo];
    },
    playersPlayingId() {
      return this.playersPlaying.map((e) => e.cdId);
    },
  },
  mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removePropsModalCascate");
      this.$store.commit("base/removeShowModalCascate");
    });
    this.playersList = [
      ...this.allConfirmedPlayers.filter(
        (e) => !this.alreadySelectedPlayerIds.includes(e.cdId)
      ),
    ];
  },
  methods: {
    onClose() {
      this.$store.commit("base/removePropsModal", "ModalMatchManager");
      this.$store.commit("base/removeShowModal", "ModalMatchManager");
    },
    selectPlayer(pPlayerId) {
      if (this.selectedPlayerId != pPlayerId) {
        this.selectedPlayerId = pPlayerId;
      } else {
        this.selectedPlayerId = null;
      }
    },
    segmentChanged(pEvent) {
      this.selectedTeam = pEvent.detail.value
        ? pEvent.detail.value
        : "selectedTeam";
    },
    getColor(pClassName) {
      if (pClassName == "primary") {
        return "color: #3880e6; --color-checked: #3880e6;";
      } else if (pClassName == "secondary") {
        return "color: #3dc2ff; --color-checked: #3dc2ff;";
      } else if (pClassName == "tertiary") {
        return "color: #5260ff; --color-checked: #5260ff;";
      } else if (pClassName == "success") {
        return "color: #2dd36f; --color-checked: #2dd36f;";
      } else if (pClassName == "warning") {
        return "color: #ffc409; --color-checked: #ffc409;";
      } else if (pClassName == "danger") {
        return "color: #eb445a; --color-checked: #eb445a;";
      }
    },
    async account() {
      if (this.selectedPlayerId) {
        this.loading = true;
        await this.genericRequest("common/account", {
          cdIdPlayer: this.selectedPlayerId,
          event: this.event,
          matchId: this.matchSummary.cdIdJogo,
          description: this.description,
        });
        this.$store.commit("base/setReloadCurrentMatch", Date.now());
        this.onClose();
        this.selectedPlayerId = null;
        this.loading = false;
      }
    },
    selectNewPlayer(pPlayerId) {
      if (this.newPlayerId != pPlayerId) {
        this.newPlayerId = pPlayerId;
      } else {
        this.newPlayerId = null;
      }
    },
    substitution() {
      this.substitutionMode = !this.substitutionMode;
      this.selectedPlayerId = null;
      this.newPlayerId = null;
    },
    async replace() {
      const playerLeavingTeam = this.playersPlaying.find(
        (e) => e.cdId == this.selectedPlayerId
      )?.chosenTeam;

      if (playerLeavingTeam && this.selectedPlayerId) {
        this.loading = true;
        const response = await this.genericRequest("common/replacePlayer", {
          cdIdMatch: this.matchSummary.cdIdJogo,
          playerLeaving: this.selectedPlayerId,
          newPlayer: { cdId: this.newPlayerId, chosenTeam: playerLeavingTeam },
        });

        if (response != "Sucesso") {
          this.message = response;
          this.isOpenToast = true;
        } else {
          this.$store.commit("base/setReloadCurrentMatch", Date.now());
          this.onClose();
        }
        this.selectedPlayerId = null;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
ion-label {
  font-size: 18px;
  height: 40px;
}

ion-icon {
  font-size: 25px;
}

.custom-ion-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
}

ion-card-content {
  padding: 0;
}

ion-select {
  padding: 10px;
}

ion-item {
  width: 100%;
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
  --highlight-height: none;
  border: none;
}

.avatar {
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

h1 {
  color: #666666;
  font-size: 21px;
  margin: 0;
  margin-bottom: 10px;
}

ion-item {
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
  --highlight-height: 4px;
  border-color: red;
  width: 100%;
  display: flex;
}

ion-icon {
  font-size: 30px;
  font-weight: bold;
}

ion-searchbar {
  width: 100% !important;
  --background: white;
  --box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
}

.more-information {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}
</style>
