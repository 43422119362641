<template>
  <ion-page>
    <Header title="Criar times" showBackButton @onClick="onClose()" />
    <Loading v-if="loading" />
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Opções</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content
          style="display: flex; justify-content: space-between; padding: 10px;"
        >
          <ion-button color="danger" @click="onClose()">cancelar</ion-button>
          <ion-button
            :disabled="amountPlayers && !itsClean"
            @click="saveAllTeams()"
            >Salvar times</ion-button
          >
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Nome do time</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-input
            placeholder="Digite um nome para o time..."
            :value="teamListLocal[selectedTeam].teamName"
            @ionChange="
              teamListLocal[selectedTeam].teamName = $event.target.value
            "
          ></ion-input>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header
          style="display: flex; height: 40px; align-items: center; justify-content: space-between;"
        >
          <ion-card-subtitle>Times</ion-card-subtitle>
          <div>
            <ion-icon
              style="font-size: 25px;"
              :icon="addCircleOutline"
              @click="createNewTeam()"
            ></ion-icon>
            <ion-icon
              style="font-size: 25px; margin-left: 20px;"
              :icon="arrowUndoCircle"
              @click="removeAllTeams()"
            ></ion-icon>
          </div>
        </ion-card-header>
        <ion-searchbar
          class="nickname-color"
          debounce="1000"
          placeholder="Buscar time"
          :value="searchTeam"
          @ionChange="searchTeam = $event.target.value"
        ></ion-searchbar>
        <ion-card-content>
          <ion-segment
            scrollable
            @ionChange="segmentChangedTeam($event)"
            :value="selectedTeam"
          >
            <ion-segment-button
              v-for="(_, index) in teamListLocal"
              :value="index"
              :style="
                highlightsTeams(searchTeam, index)
                  ? 'background: #3880ff !important; color: white; --color-checked: white;'
                  : ''
              "
              :key="index"
            >
              <ion-label class="nickname-color">{{ index + 1 }}</ion-label>
            </ion-segment-button>
          </ion-segment>
          <ion-item lines="none">
            <div
              style="display: flex; flex-wrap: wrap; padding-top: 10px; padding-bottom: 10px;"
            >
              <ion-chip
                v-for="item in teamListLocal[selectedTeam].players"
                :key="item.cdId"
              >
                <Avatar
                  class="avatar"
                  width="35"
                  height="35"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
                <ion-label style="max-width: 200px;"
                  ><h2 class="nickname-color">{{ item.apelido }}</h2></ion-label
                >
                <ion-icon
                  :icon="closeCircle"
                  color="danger"
                  @click="removePlayer(item, selectedTeam)"
                ></ion-icon>
              </ion-chip>
            </div>
          </ion-item>
          <div style="display: flex;">
            <div
              v-if="teamListLocal[selectedTeam].players.length > 0"
              class="actions-button"
              style="background-color: #3880e6;"
              :style="teamListLocal.length == 1 ? 'width: 100%;' : ''"
              @click="clearListTeam()"
            >
              Limpar
            </div>
            <div
              v-if="teamListLocal.length > 1"
              class="actions-button"
              style="background-color: #eb445a;"
              :style="
                teamListLocal[selectedTeam].players.length == 0
                  ? 'width: 100%;'
                  : ''
              "
              @click="removeTeam()"
            >
              Remover
            </div>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Jogadores</ion-card-subtitle>
        </ion-card-header>
        <ion-searchbar
          class="nickname-color"
          debounce="1000"
          placeholder="Buscar jogador"
          :value="searchValue"
          @ionChange="searchValue = $event.target.value"
        ></ion-searchbar>
        <ion-card-content>
          <ion-list>
            <div
              class="container-team-list"
              v-for="item in allConfirmedPlayersC.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <ion-item
                lines="none"
                style="disply: flex; border-bottom: 1px solid #d9d9d9;"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
                <ion-label>
                  <h2 class="nickname-color">{{ item.apelido }}</h2>
                </ion-label>
                <ion-fab-button
                  :disabled="
                    teamListLocal.length > 0 &&
                      teamListLocal[selectedTeam].players.length >=
                        maximumPlayersPerTeam
                  "
                  fill="clear"
                  class="ion-no-padding"
                  style="border: 1px solid #e9e9e9; border-radius: 50%;"
                  @click.stop="selectPlayer(item)"
                  ><ion-icon :icon="arrowUp" color="success"></ion-icon
                ></ion-fab-button>
              </ion-item>
            </div>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="2000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "ModalCreateTeams",
  mixins: [requestManager, computedManager],
  components: {
    Header,
    Avatar,
    IonToast,
    Loading,
  },
  data() {
    return {
      isOpenToast: false,
      message: "",
      maximumPlayersPerTeam: 3,
      searchTeam: "",
      searchValue: "",
      playersList: [],
      selectedTeam: "0",
      teamListLocal: [{ sequence: 1, teamName: "", players: [] }],
    };
  },
  computed: {
    matchSummary() {
      return this.$store.state.base.matchSummary;
    },
    playersPlaying() {
      return [
        ...this.matchSummary.playersTeamOne.map((e) => e.cdId),
        ...this.matchSummary.playersTeamTwo.map((e) => e.cdId),
      ];
    },
    allConfirmedPlayers() {
      return this.$store.state.base.allConfirmedPlayers;
    },
    teamList() {
      return this.$store.state.base.teamList;
    },
    alreadySelectedPlayerIds() {
      return this.teamListLocal
        .map((p) => p.players)
        .flat()
        .map((e) => e.cdId);
    },
    allConfirmedPlayersC() {
      if (this.searchValue) {
        return this.playersList.filter((p) => {
          return `${p.nome}${p.apelido}`
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        return this.playersList;
      }
    },
    amountPlayers() {
      const teams = this.teamListLocal.map((e) => e.players);
      const value = teams.filter((e) => e.length < 3);
      return value.length > 0;
    },
    itsClean() {
      return (
        this.teamListLocal.length == 1 && this.teamListLocal[0].players == 0
      );
    },
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removePropsModalCascate");
      this.$store.commit("base/removeShowModalCascate");
    });

    const teamList = JSON.parse(JSON.stringify(this.teamList));
    if (teamList.length > 0) this.teamListLocal = teamList;

    this.playersList = [
      ...this.allConfirmedPlayers.filter(
        (e) => !this.alreadySelectedPlayerIds.includes(e.cdId)
      ),
    ];
  },
  methods: {
    onClose() {
      this.$store.commit("base/removePropsModal", "ModalCreateTeams");
      this.$store.commit("base/removeShowModal", "ModalCreateTeams");
    },
    segmentChangedTeam(pEvent) {
      this.selectedTeam = pEvent.detail.value ? pEvent.detail.value : "1";
    },
    async saveAllTeams() {
      this.loading = true;
      let objectRequest = [];

      if (
        this.teamListLocal.length > 0 &&
        this.teamListLocal[0].players.length > 0
      ) {
        this.teamListLocal.forEach((e) => {
          objectRequest.push({
            teamName: e.teamName,
            sequence: e.sequence,
            playerIds: e.players.map((e) => e.cdId),
          });
        });
      } else {
        objectRequest = [];
      }

      const response = await this.genericRequest("common/saveAllTeams", {
        teams: objectRequest,
      });
      if (response != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      } else {
        if (
          this.teamListLocal.length > 0 &&
          this.teamListLocal[0].players.length > 0
        ) {
          this.$store.commit("base/setTeamList", this.teamListLocal);
        } else {
          this.$store.commit("base/setTeamList", []);
        }
        this.onClose();
      }
      this.loading = false;
    },
    createNewTeam() {
      this.teamListLocal = [
        ...this.teamListLocal,
        { sequence: 0, teamName: "", players: [] },
      ];
      this.teamListLocal.forEach((e, index) => (e.sequence = index + 1));
      this.selectedTeam = this.teamListLocal.length - 1;
    },
    selectPlayer(pPlayer) {
      this.playersList = this.playersList.filter((e) => e.cdId != pPlayer.cdId);
      this.teamListLocal[this.selectedTeam].players.push(pPlayer);
    },
    removePlayer(pPlayer) {
      if (this.playersPlaying.includes(pPlayer.cdId)) {
        this.message =
          "O jogador não pode ser removido de um time que está em uma partida em andamento.";
        this.isOpenToast = true;
        return;
      }

      this.teamListLocal[this.selectedTeam].players = this.teamListLocal[
        this.selectedTeam
      ].players.filter((e) => e.cdId != pPlayer.cdId);

      this.playersList = [...this.playersList, pPlayer];
    },
    clearListTeam() {
      const hasPlayersPlaying = this.teamListLocal[
        this.selectedTeam
      ].players.filter((e) => this.playersPlaying.includes(e.cdId));
      if (hasPlayersPlaying.length > 0) {
        this.message =
          "A lista não pode ser limpada pois os jogadores estão em uma partida que está ocorrendo.";
        this.isOpenToast = true;
        return;
      }

      this.playersList = [
        ...this.playersList,
        ...this.teamListLocal[this.selectedTeam].players,
      ];

      this.teamListLocal[this.selectedTeam].players = [];
    },
    removeTeam() {
      const hasPlayersPlaying = this.teamListLocal[
        this.selectedTeam
      ].players.filter((e) => this.playersPlaying.includes(e.cdId));
      if (hasPlayersPlaying.length > 0) {
        this.message =
          "O time não pode ser removido enquanto estiver participando de uma partida";
        this.isOpenToast = true;
        return;
      }

      this.playersList = [
        ...this.playersList,
        ...this.teamListLocal[this.selectedTeam].players,
      ];

      const selectedTeam = this.selectedTeam;
      this.selectedTeam = this.teamListLocal.length - 2;
      this.teamListLocal.splice(selectedTeam, 1);
      this.teamListLocal.forEach((e, index) => (e.sequence = index + 1));
    },
    removeAllTeams() {
      const { teamsPlaying, teamsNoPlaying } = this.separateTeamsPlaying();
      const playersList = teamsNoPlaying.map((e) => e.players);

      this.playersList = [...playersList.flat(), ...this.playersList];
      this.selectedTeam = "0";

      if (teamsPlaying.length > 0) {
        this.teamListLocal = teamsPlaying;
        this.message =
          "Os times que estão em uma partida não podem ser removido.";
        this.isOpenToast = true;
      } else {
        this.teamListLocal = [{ sequence: 1, teamName: "", players: [] }];
      }
    },
    highlightsTeams(pSearchTeamName, pIndex) {
      if (pSearchTeamName) {
        const searchValues = `${this.teamListLocal[pIndex].teamName +
          JSON.stringify(
            this.teamListLocal[pIndex].players.map(
              (p) => `${p.apelido + p.nome}`
            )
          )}`;
        return searchValues
          .toLowerCase()
          .includes(pSearchTeamName.toLowerCase());
      } else {
        return false;
      }
    },
    separateTeamsPlaying() {
      const teamsPlaying = [];
      const teamsNoPlaying = [];

      this.teamListLocal.forEach((e) => {
        if (e.players.filter((e) => this.playersPlaying.includes(e.cdId)).length > 0) {
          teamsPlaying.push(e);
        } else {
          teamsNoPlaying.push(e);
        }
      });

      return { teamsPlaying, teamsNoPlaying };
    },
    // randomTeam() {
    //   this.playersList = this.playersList.sort(() => Math.random() - 0.5);
    //   while (this.playersList.length >= 3) {
    //     let newList = [];
    //     for (let i = 0; i < 3; i++) {
    //       let index = Math.floor(Math.random() * this.playersList.length);
    //       let randomPlayer = this.playersList[index];
    //       newList.push(randomPlayer);
    //       this.playersList.splice(index, 1);
    //     }
    //     this.teamListLocal.push(newList);
    //   }
    // },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
  },
};
</script>
<style scoped>
h2 {
  margin: 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

p {
  margin: 0;
  font-size: 25px;
}

ion-label {
  font-size: 18px;
}

ion-input {
  font-size: 16px;
}

ion-fab-button {
  width: 30px;
  height: 30px;
}

ion-icon {
  font-size: 18px;
}

ion-searchbar {
  width: 100% !important;
  --background: white;
  --box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
}

ion-list {
  padding: 0;
}

ion-list ion-item {
  padding-top: 0px;
}

ion-chip {
  height: 35px;
  --background: rgb(240, 240, 240);
  border: 1px solid #d9d9d9;
}

ion-chip ion-avatar {
  width: 35px;
  height: 35px;
  margin-left: -12px;
}

ion-card-content {
  padding: 0;
}

ion-item {
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
  --highlight-height: none;
  width: 100%;
  display: flex;
}

ion-picker input {
  font-weight: bold;
}

.container-team-list {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
}

.container-team-list ion-icon {
  font-size: 25px;
}

.avatar {
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.actions-button {
  width: 50%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}
</style>
