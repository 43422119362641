import {
  create,
  save,
  arrowDownOutline,
  arrowUpOutline,
  trash,
  statsChart,
  cameraReverse,
  person,
  at,
  happy,
  documentText,
  code,
  body,
  heart,
  settings,
  checkmarkCircle,
  closeCircle,
  listCircle,
  arrowUp,
  add,
  remove,
  arrowBackOutline,
  chevronDownCircleOutline,
  checkmarkDoneCircle,
  time,
  close,
  shuffleOutline,
  removeCircle,
  informationCircle,
  home,
  barChart,
  readerOutline,
  personCircleOutline,
  duplicateSharp,
  arrowDown,
  football,
  trophySharp,
  key,
  people,
  arrowUndoCircle,
  medical,
  starSharp,
  medalSharp,
  addCircleOutline
} from "ionicons/icons";

export default {
  data() {
    return { request: null };
  },
  computed: {
    /* Início computed relacionadas a ícones */
    create() {
      return create;
    },
    people() {
      return people;
    },
    save() {
      return save;
    },
    arrowDownOutline() {
      return arrowDownOutline;
    },
    arrowUpOutline() {
      return arrowUpOutline;
    },
    trash() {
      return trash;
    },
    statsChart() {
      return statsChart;
    },
    cameraReverse() {
      return cameraReverse;
    },
    person() {
      return person;
    },
    at() {
      return at;
    },
    documentText() {
      return documentText;
    },
    happy() {
      return happy;
    },
    code() {
      return code;
    },
    body() {
      return body;
    },
    heart() {
      return heart;
    },
    closeCircle() {
      return closeCircle;
    },
    settings() {
      return settings;
    },
    arrowUp() {
      return arrowUp;
    },
    listCircle() {
      return listCircle;
    },
    add() {
      return add;
    },
    remove() {
      return remove;
    },
    checkmarkCircle() {
      return checkmarkCircle;
    },
    arrowUndoCircle() {
      return arrowUndoCircle;
    },
    arrowBackOutline() {
      return arrowBackOutline;
    },
    chevronDownCircleOutline() {
      return chevronDownCircleOutline;
    },
    checkmarkDoneCircle() {
      return checkmarkDoneCircle;
    },
    close() {
      return close;
    },
    time() {
      return time;
    },
    removeCircle() {
      return removeCircle;
    },
    informationCircle() {
      return informationCircle;
    },
    home() {
      return home;
    },
    barChart() {
      return barChart;
    },
    readerOutline() {
      return readerOutline;
    },
    personCircleOutline() {
      return personCircleOutline;
    },
    duplicateSharp() {
      return duplicateSharp;
    },
    arrowDown() {
      return arrowDown;
    },
    shuffleOutline() {
      return shuffleOutline;
    },
    football() {
      return football;
    },
    trophySharp() {
      return trophySharp;
    },
    key() {
      return key;
    },
    medical() {
      return medical;
    },
    starSharp() {
      return starSharp;
    },
    medalSharp() {
      return medalSharp;
    },
    addCircleOutline() {
      return addCircleOutline;
    },
    /* Fim computed relacionadas a ícones */
    playerInformation() {
      return this.$store.state.profile.playerInformation;
    },
    imagePlayer() {
      return this.$store.state.profile.playerImage;
    },
    url() {
      return "https://futeboleiros-oficial.000webhostapp.com/";
    },
    accessLevel() {
      return this.playerInformation.nivelAcesso;
    },
    isPremium() {
      return this.playerInformation.premium;
    },
    loading: {
      get() {
        return this.$store.state.common.loading;
      },
      set(pValue) {
        this.$store.commit("common/setLoading", pValue);
      },
    },
    theme() {
      return this.$store.state.common.theme;
    },
  },
  methods: {},
};
