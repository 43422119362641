<template>
  <div class="loading">
    <img src="@/img/loading.svg" />
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>
<style scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.loading img {
  height: 60px;
  margin-top: -30px;
  margin-right: -30px;
  position: absolute;
  z-index: 999;
  right: 50%;
  top: 50%;
  width: 60px;
  -webkit-animation: rotating 0.8s infinite linear;
  -moz-animation: rotating 0.8s infinite linear;
  -ms-animation: rotating 0.8s infinite linear;
  -o-animation: rotating 0.8s infinite linear;
  animation: rotating 0.8s infinite linear;
}
</style>
