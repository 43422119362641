<template>
  <ion-page>
    <Header title="Troféus conquistados" showBackButton @onClick="onClose()" />
    <Loading v-if="loading" />
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <h2 v-if="allMatchesTrophies.length == 0 && !loading">
        Nenhuma informação encontrada
      </h2>
      <ion-card
        v-for="(match, index) in allMatchesTrophies"
        class="current-football-match-card"
        :key="index"
      >
        <ion-card-header>
          <ion-card-subtitle
            style="width: 100%; display: flex; justify-content: space-between; align-items: center;"
          >
            Contra do dia {{ getDateTimeFormatted(match.dtHrCriacao) }}
          </ion-card-subtitle>
          <div style="display: flex;">
            <ion-icon
              :icon="listCircle"
              style="font-size: 25px; margin-left: 20px;"
              @click="setOpenModalMatchInformation(match.cdIdJogo)"
            />
          </div>
        </ion-card-header>
        <div class="match-teams">
          <div
            style="display: flex; justify-content: space-between; align-items: center;"
          >
            <div class="team">
              <template
                v-for="item in match.playersTeamOne.sort((a, b) =>
                  a.apelido > b.apelido ? 1 : -1
                )"
                :key="item.cdId"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
              </template>
            </div>
            <div
              style="width: 50%; display: flex; align-items: center; justify-content: space-around; border-left: 1px solid gray; height: 50px;"
            >
              <span
                style="display: flex; align-items: center; font-size:25px;"
                >{{ match.team1 }}</span
              >
              <span style="width: 25px; height: 25px;">
                <ion-icon
                  style="display: flex; align-items: center;"
                  v-if="match.vlStatus == 'I' && match.team1 > match.team2"
                  :icon="trophySharp"
                  color="warning"
                />
              </span>
            </div>
          </div>

          <div
            style="display: flex; justify-content: space-between; align-items: center; margin-top: 15px;"
          >
            <div class="team">
              <template
                v-for="item in match.playersTeamTwo.sort((a, b) =>
                  a.apelido > b.apelido ? 1 : -1
                )"
                :key="item.cdId"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
              </template>
            </div>
            <div
              style="width: 50%; display: flex; align-items: center; justify-content: space-around; border-left: 1px solid gray; height: 50px;"
            >
              <span
                style="display: flex; align-items: center; font-size:25px;"
                >{{ match.team2 }}</span
              >
              <span style="width: 25px; height: 25px;">
                <ion-icon
                  v-if="match.vlStatus == 'I' && match.team2 > match.team1"
                  :icon="trophySharp"
                  color="warning"
                />
              </span>
            </div>
          </div>
        </div>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "ModalTrophies",
  mixins: [requestManager, computedManager],
  components: {
    Header,
    Avatar,
    Loading,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    playerId: {
      type: String,
      default: "0",
    },
  },
  computed: {
    allMatchesTrophies() {
      return this.$store.state.base.allMatchesTrophies;
    },
    anotherPlayerProfile() {
      return this.$store.state.base.anotherPlayerProfile;
    },
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removePropsModalCascate");
      this.$store.commit("base/removeShowModalCascate");
    });

    await this.init();
  },
  methods: {
    onClose() {
      this.$store.commit("base/removePropsModal", "ModalTrophies");
      this.$store.commit("base/removeShowModal", "ModalTrophies");
    },
    async init() {
      this.loading = true;
      const response = await this.genericRequest(
        "common/getAllMatchesTrophies",
        {
          playerId: this.playerId,
        }
      );
      if (typeof response != "string") {
        this.$store.commit("base/setAllMatchesTrophies", response);
      } else {
        this.$store.commit("base/setAllMatchesTrophies", []);
      }
      this.loading = false;
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/removePropsModal", "ModalPlayerProfile");
      this.$store.commit("base/removeShowModal", "ModalPlayerProfile");
      setTimeout(() => {
        this.onClose();
        this.$store.commit("base/addPropsModal", {
          name: "ModalPlayerProfile",
          prop: pPlayerId,
        });

        this.$store.commit("base/addShowModal", "ModalPlayerProfile");
      }, 100);
    },
    setOpenModalMatchInformation(pMatchId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalMatchInformation",
        prop: pMatchId,
      });

      this.$store.commit("base/addShowModal", "ModalMatchInformation");
    },
    getDateTimeFormatted(pDateTime) {
      var dateTime = new Date(pDateTime);

      return dateTime.toLocaleDateString("pt-BR");
    },
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
  },
};
</script>

<style scoped>
h2 {
  margin: auto;
  margin-top: 50px;
  text-align: center;
  color: #737373;
  font-size: 1rem;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning {
  -webkit-animation: rotating 1.5s infinite linear;
  -moz-animation: rotating 1.5s infinite linear;
  -ms-animation: rotating 1.5s infinite linear;
  -o-animation: rotating 1.5s infinite linear;
  animation: rotating 1.5s infinite linear;
}

p {
  margin: 0;
  margin-top: 10px;
}

ion-icon {
  font-size: 25px;
}

.match-teams {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  align-items: stretch;
  padding-bottom: 20px;
  padding-top: 10px;
  padding: 10px;
}

.team {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding-left: 25px;
  align-items: center;
}

.more-information {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}

.avatar {
  border-radius: 50%;
  border: 3px solid white;
  margin-left: -15px;
  background-color: white;
}
</style>
