const state = {
  allPlayers: [],
  allPlayersPremium: [],
  allConfirmedPlayers: [],
  podiumList: [],
  teamList: [{ sequence: 1, teamName: "", players: [] }],
  matchSummary: {
    cdIdJogo: null,
    changed: null,
    playersTeamOne: [],
    playersTeamTwo: [],
    team1: null,
    team2: null,
  },
  highlights: [],
  anotherPlayerProfile: {},
  informationMatch: { events: [], owner: null, cdIdMatch: null },
  allSeason: [],
  ranking: [],
  allMatchesDay: [],
  allMatchesTrophies: [],
  allBirthdays: [],
  lastWinner: [],
  showModal: [],
  propsModal: [],
  reloadCurrentMatch: 0,
  amountTrophies: 0,
};

const getters = {
  getAllPlayers(state) {
    return state.allPlayers;
  },
  getAllPlayersPremium(state) {
    return state.allPlayersPremium;
  },
  getAllConfirmedPlayers(state) {
    return state.allConfirmedPlayers;
  },
  getPodiumList(state) {
    return state.podiumList;
  },
  getTeamList(state) {
    return state.teamList;
  },
  getMatchSummary(state) {
    return state.matchSummary;
  },
  getHighlights(state) {
    return state.highlights;
  },
  getAnotherPlayerProfile(state) {
    return state.anotherPlayerProfile;
  },
  getInformationMatch(state) {
    return state.informationMatch;
  },
  getAllSeason(state) {
    return state.allSeason;
  },
  getRanking(state) {
    return state.ranking;
  },
  getAllMatchesDay(state) {
    return state.allMatchesDay;
  },
  getAllMatchesTrophies(state) {
    return state.allMatchesTrophies;
  },
  getAllBirthdays(state) {
    return state.allBirthdays;
  },
  getLastWinner(state) {
    return state.lastWinner;
  },
  getPropsModal(state) {
    return state.propsModal;
  },
  getShowModal(state) {
    return state.showModal;
  },
  getReloadCurrentMatch(state) {
    return state.reloadCurrentMatch;
  },
  getAmountTrophies(state) {
    return state.amountTrophies;
  },
};

const mutations = {
  setAllPlayers(state, payload) {
    state.allPlayers = payload;
  },
  setAllPlayersPremium(state, payload) {
    state.allPlayersPremium = payload;
  },
  setAllConfirmedPlayers(state, payload) {
    state.allConfirmedPlayers = payload;
  },
  setPodiumList(state, payload) {
    state.podiumList = payload;
  },
  setTeamList(state, payload) {
    state.teamList = payload;
  },
  setMatchSummary(state, payload) {
    state.matchSummary = payload;
  },
  setHighlights(state, payload) {
    state.highlights = payload;
  },
  setAnotherPlayerProfile(state, payload) {
    state.anotherPlayerProfile = payload;
  },
  setInformationMatch(state, payload) {
    state.informationMatch = payload;
  },
  setAllSeason(state, payload) {
    state.allSeason = payload;
  },
  setRanking(state, payload) {
    state.ranking = payload;
  },
  setAllMatchesDay(state, payload) {
    state.allMatchesDay = payload;
  },
  setAllMatchesTrophies(state, payload) {
    state.allMatchesTrophies = payload;
  },
  setAllBirthdays(state, payload) {
    state.allBirthdays = payload;
  },
  setLastWinner(state, payload) {
    state.lastWinner = payload;
  },
  addPropsModal(state, payload) {
    if (!state.propsModal.map((e) => e.name).includes(payload.name)) {
      state.propsModal.push(payload);
    }
  },
  removePropsModal(state, payload) {
    const index = state.propsModal.map((e) => e.name).indexOf(payload);
    if (index > -1) {
      state.propsModal.splice(index, 1);
    }
  },
  addShowModal(state, payload) {
    if (!state.showModal.includes(payload)) {
      state.showModal.push(payload);
    }
  },
  removeShowModal(state, payload) {
    const index = state.showModal.indexOf(payload);
    if (index > -1) {
      state.showModal.splice(index, 1);
    }
  },
  removePropsModalCascate(state) {
    state.propsModal.pop();
  },
  removeShowModalCascate(state) {
    state.showModal.pop();
  },
  setReloadCurrentMatch(state, payload) {
    state.reloadCurrentMatch = payload;
  },
  setAmountTrophies(state, payload) {
    state.amountTrophies = payload;
  },
};

const actions = {
  resetStates() {
    state.allPlayers = [];
    state.allPlayersPremium = [];
    state.allConfirmedPlayers = [];
    state.podiumList = [];
    state.matchSummary = {
      cdIdJogo: null,
      changed: null,
      playersTeamOne: [],
      playersTeamTwo: [],
      team1: null,
      team2: null,
    };
    state.highlights = [];
    state.anotherPlayerProfile = {};
    state.informationMatch = { events: [], owner: null, cdIdMatch: null };
    state.allSeason = [];
    state.ranking = [];
    state.allMatchesDay = [];
    state.allMatchesTrophies = [];
    state.allBirthdays = [];
    state.propsModal = [];
    state.showModal = [];
    state.amountTrophies = 0;
  },
};

export const base = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
