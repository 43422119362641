import { createStore } from "vuex";
import { base } from "./Base.js";
import { common } from "./Common.js";
import { login } from "./Login.js";
import { profile } from "./Profile.js";

export const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    base,
    common,
    login,
    profile,
  },
});
