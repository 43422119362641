import { default as http } from "@/service/httpService.js";

const state = {
  loading: false,
  theme: "primary",
  token: "",
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getTheme(state) {
    return state.theme;
  },
  getToken(state) {
    return state.token;
  },
};

const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setTheme(state, payload) {
    state.theme = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
};

const actions = {
  /* GET */
  async getAllPlayers(_, payload) {
    return http
      .post("lista-jogador.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllPlayersPremium(_, payload) {
    return http
      .post("lista-jogador-premium.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllSeason(_, payload) {
    return http
      .post("lista-temporada.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllMatchInformation(_, payload) {
    return http
      .post("lista-informacao-partida.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getCurrentMatch(_, payload) {
    return http
      .post("partida-atual.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getProfileInformation(_, payload) {
    return http
      .post("perfil.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getPodium(_, payload) {
    return http
      .post("lista-podio.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getHighlights(_, payload) {
    return http
      .post("lista-destaques.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getRanking(_, payload) {
    return http
      .post("lista-ranking.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllConfirmedPlayer(_, payload) {
    return http
      .post("lista-jogador-confirmado.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllMatchesDay(_, payload) {
    return http
      .post("lista-partidas.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllMatchesTrophies(_, payload) {
    return http
      .post("lista-trofeus.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAmountTrophies(_, payload) {
    return http
      .post("conta-trofeus.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllBirthdays(_, payload) {
    return http
      .post("aniversariantes.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async getAllTeams(_, payload) {
    return http
      .post("lista-times.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  /* INSERT */
  async createMatch(_, payload) {
    return http
      .post("cadastra-partida.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async account(_, payload) {
    return http
      .post("contabilizar.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async endMatch(_, payload) {
    return http
      .post("finaliza-partida.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async saveConfirmedList(_, payload) {
    return http
      .post("cadastra-lista-jogador-confirmado.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async saveAllTeams(_, payload) {
    return http
      .post("cadastra-times.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  /* UPDATE */
  async updatePermissions(_, payload) {
    return http
      .post("administracao.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async editMatch(_, payload) {
    return http
      .post("edita-partida.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async confirmRequestChangePassword(_, payload) {
    return http
      .post("confirma-recuperacao-senha.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async replacePlayer(_, payload) {
    return http
      .post("substitui-jogador.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  /* DELETE */
  async deleteEvent(_, payload) {
    return http
      .post("deleta-evento.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
  async deleteMatch(_, payload) {
    return http
      .post("deleta-partida.php", JSON.stringify(payload))
      .then((response) => response.data);
  },
};

export const common = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
