<template>
  <ion-header>
    <ion-toolbar>
      <div class="custom-ion-toolbar" style="color: #666666;">
        <ion-fab-button
          v-if="showBackButton"
          fill="clear"
          class="ion-no-padding"
          @click="onClick()"
        >
          <ion-icon :color="theme" :icon="arrowBackOutline" />
        </ion-fab-button>
        <p
          :style="
            showBackButton && !showButtonsSettings
              ? 'padding-right: 40px'
              : showButtonsSettings
              ? 'padding-left: 40px;'
              : ''
          "
        >
          {{ title }}
        </p>
        <ion-fab-button
          v-if="showButtonsSettings"
          fill="clear"
          class="ion-no-padding"
        >
          <ion-icon :color="theme" :icon="cloudDownload"></ion-icon>
        </ion-fab-button>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { IonHeader, IonToolbar } from "@ionic/vue";
import { arrowBackOutline, settings, cloudDownload } from "ionicons/icons";

export default {
  name: "Header",
  components: {
    IonHeader,
    IonToolbar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showButtonsSettings: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    arrowBackOutline() {
      return arrowBackOutline;
    },
    settings() {
      return settings;
    },
    cloudDownload() {
      return cloudDownload;
    },
    theme() {
      return this.$store.state.common.theme;
    },
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>
<style scoped>
ion-toolbar {
  --background: #f5f7f9 !important;
}

.custom-ion-toolbar {
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
}

.custom-ion-toolbar p {
  text-align: center;
  margin: auto;
  font-size: 1.25rem;
}
</style>
