import { createRouter, createWebHistory } from "@ionic/vue-router";
import Tabs from "@/views/Tabs.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/recoverPassword",
    component: () => import("@/views/RecoverPassword.vue"),
  },
  {
    path: "/Prelude",
    component: () => import("@/views/Prelude.vue"),
  },
  {
    path: "/tabs/",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "/tabs/Dashboard",
      },
      {
        path: "Dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "Ranking",
        component: () => import("@/views/Ranking.vue"),
      },
      {
        path: "Administracao",
        component: () => import("@/views/Administration.vue"),
      },
      {
        path: "Perfil",
        component: () => import("@/views/Profile.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const isPublic = to.matched.some(record => record.meta.public);
//   const onlyWhenLoggedOut = to.matched.some(
//       record => record.meta.onlyWhenLoggedOut
//   );
//   const loggedIn = !!TokenService.getToken();

//   if (!isPublic && !loggedIn) {
//     return next({
//       path: "/login",
//       query: { redirect: to.fullPath }
//     });
//   }

//   if (loggedIn && onlyWhenLoggedOut) {
//     return next("/tabs/tab1");
//   }

//   next();
// });

export default router;
