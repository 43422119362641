<template>
  <ion-page>
    <Header
      :title="
        selectedDay == '0'
          ? 'Partidas de hoje'
          : selectedDay == '1'
          ? 'Partidas de ontem'
          : selectedDay == '2'
          ? 'Partidas de anteontem'
          : 'Partidas de ' + getDate(selectedDay)
      "
      showBackButton
      @onClick="onClose()"
    />
    <Loading v-if="loading" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <h2 v-if="allMatchesDay.length == 0 && !loading">
        Nenhum partida encontrada
      </h2>
      <ion-card
        v-for="(match, index) in allMatchesDay"
        class="current-football-match-card"
        :key="index"
      >
        <ion-card-header :style="match.matchType == 'C' ? !checked ? 'background-color: #2d2d2d; color: white;' : 'background-color: #3880ff !important; color: white !important;' : ''">
          <ion-card-subtitle
            style="width: 100%; display: flex; justify-content: space-between; align-items: center;"
            :style="match.matchType == 'C' ? !checked ? 'color: white;' : 'color: white !important;' : ''"
          >
            Partida {{ allMatchesDay.length - index }}
          </ion-card-subtitle>
          <div style="display: flex;">
            <ion-icon
              v-if="match.vlStatus == 'A'"
              class="spinning"
              style="font-size: 25px;"
              :icon="football"
            />
            <ion-icon
              :icon="listCircle"
              style="font-size: 25px; margin-left: 20px;"
              @click="setOpenModalMatchInformation(match.cdIdJogo)"
            />
            <ion-icon
              v-if="
                accessLevel < 4 &&
                  match.vlStatus == 'I' &&
                  !matchSummary?.cdIdJogo
              "
              style="margin-left: 20px;"
              :icon="create"
              @click="setOpenActionSheet(true, match.cdIdJogo)"
            />
          </div>
        </ion-card-header>
        <p
          style="display: flex; justify-content: center; font-size: 20px; padding: 10px;"
        >
          <span style="width: 25px; height: 25px;">
            <ion-icon
              v-if="match.vlStatus == 'I' && match.team1 > match.team2"
              :icon="trophySharp"
              color="warning"
            />
          </span>

          <span style="margin-left: 10px; margin-right: 10px;">{{
            match.team1
          }}</span
          >x
          <span style="margin-left: 10px; margin-right: 10px;">{{
            match.team2
          }}</span>
          <span style="width: 25px; height: 25px;">
            <ion-icon
              v-if="match.vlStatus == 'I' && match.team2 > match.team1"
              :icon="trophySharp"
              color="warning"
            />
          </span>
        </p>
        <div class="match-teams">
          <div class="team" style="border-right: 1px solid gray;">
            <template
              v-for="item in match.playersTeamOne.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <Avatar
                class="avatar"
                width="50"
                height="50"
                :imageUrl="item.imagemUrl"
                eventOn
                @onClick="setOpenModalPlayerProfile(item.cdId)"
              />
            </template>
          </div>

          <div class="team">
            <template
              v-for="item in match.playersTeamTwo.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <Avatar
                class="avatar"
                width="50"
                height="50"
                :imageUrl="item.imagemUrl"
                eventOn
                @onClick="setOpenModalPlayerProfile(item.cdId)"
              />
            </template>
          </div>
        </div>
      </ion-card>

      <ion-action-sheet
        :is-open="isOpenActionSheet"
        header="Opções"
        :buttons="buttonsAlert"
        @onDidDismiss="setOpenActionSheet(false)"
      >
      </ion-action-sheet>

      <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="2000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonActionSheet, IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "ModalMatchesList",
  mixins: [requestManager, computedManager],
  components: {
    IonPage,
    IonContent,
    IonActionSheet,
    IonToast,
    Header,
    Avatar,
    Loading,
  },
  computed: {
    allMatchesDay() {
      return this.$store.state.base.allMatchesDay;
    },
    matchSummary() {
      return this.$store.state.base.matchSummary;
    },
  },
  props: {
    selectedDay: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      isOpenActionSheet: false,
      isOpenToast: false,
      playerId: null,
      matchId: null,
      message: "",
      buttonsAlert: [
        {
          text: "Cancelar",
          icon: this.close,
          cssClass: "close",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "Editar partida",
          role: "destructive",
          icon: this.create,
          cssClass: "end-game",
          handler: () => {
            this.editMatch();
          },
        },
      ],
      checked: false,
    };
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removePropsModalCascate");
      this.$store.commit("base/removeShowModalCascate");
    });
    if (!this.allMatchesDay || this.allMatchesDay.length == 0) {
      await this.init();
    }

    this.checked = !!localStorage.getItem("theme");
  },
  methods: {
    onClose() {
      this.$store.commit("base/removePropsModal", "ModalMatchesList");
      this.$store.commit("base/removeShowModal", "ModalMatchesList");
    },
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getAllMatchesDay", {
        selectedDay: this.selectedDay,
      });
      if (typeof response != "string") {
        this.$store.commit("base/setAllMatchesDay", response);
      } else {
        this.$store.commit("base/setAllMatchesDay", []);
      }
      this.loading = false;
    },
    getDate(pValue) {
      let d = new Date();
      d.setDate(d.getDate() - pValue);
      return d.toLocaleDateString("pt-br");
    },
    async editMatch() {
      if (this.matchId) {
        this.loading = true;
        const response = await this.genericRequest("common/editMatch", {
          token: this.token,
          cdIdMatch: this.matchId,
        });

        if (response != "Sucesso") {
          this.message = response;
          this.isOpenToast = true;
        } else {
          this.$store.commit("base/setAllMatchesDay", []);
          this.$store.commit("base/setReloadCurrentMatch", Date.now());
          this.onClose();
        }
        this.loading = false;
      }
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    setOpenModalMatchInformation(pMatchId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalMatchInformation",
        prop: pMatchId,
      });

      this.$store.commit("base/addShowModal", "ModalMatchInformation");
    },
    setOpenActionSheet(pState, pMatchId) {
      this.matchId = pMatchId;
      this.isOpenActionSheet = pState;
    },
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
  },
};
</script>
<style scoped>
h2 {
  margin: auto;
  margin-top: 50px;
  text-align: center;
  color: #737373;
  font-size: 1rem;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning {
  -webkit-animation: rotating 1.5s infinite linear;
  -moz-animation: rotating 1.5s infinite linear;
  -ms-animation: rotating 1.5s infinite linear;
  -o-animation: rotating 1.5s infinite linear;
  animation: rotating 1.5s infinite linear;
}

p {
  margin: 0;
  margin-top: 10px;
}

ion-icon {
  font-size: 25px;
}

.match-teams {
  display: flex;
  justify-content: space-around;
  display: flex;
  align-items: stretch;
  padding-bottom: 20px;
  padding-top: 10px;
}

.team {
  display: flex;
  justify-content: center;
  width: 50%;
  padding-left: 15px;
  flex-wrap: wrap;
}

.more-information {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}

.avatar {
  border-radius: 50%;
  border: 3px solid white;
  margin-left: -15px;
  background-color: white;
}
</style>
