<template>
  <ion-page>
    <Header title="Conquistas" showBackButton @onClick="onClose()" />
    <Loading v-if="loading" />
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <h2>
        Esta funcionalidade ainda não foi implementada
      </h2>
    </ion-content>
  </ion-page>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "ModalAchievements",
  mixins: [requestManager, computedManager],
  components: {
    Header,
    Loading,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {},
  computed: {},
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removeShowModalCascate");
    });
  },
  methods: {
    onClose() {
      this.$store.commit("base/removeShowModal", "ModalAchievements");
    },
    async init() {},
  },
};
</script>

<style scoped>
h2 {
  margin: auto;
  margin-top: 50px;
  width: 90%;
  text-align: center;
  color: #737373;
  font-size: 1rem;
}
</style>
