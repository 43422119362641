<template>
  <div class="container" :style="`width: ${width}px; height: ${width}px;`">
    <ion-avatar @click.stop="onClick()">
      <img
        v-if="imageUrlC && !imageError"
        :src="`${imageUrlC}`"
        @error="imageError = true"
      />
      <img v-else src="@/img/logo.svg" />
      <ion-icon v-if="isProfile" :icon="cameraReverse"></ion-icon>
    </ion-avatar>
  </div>
</template>

<script>
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "Avatar",
  mixins: [computedManager],
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    eventOn: {
      type: Boolean,
      default: false,
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      required: true,
      default: "",
    },
    height: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      imageError: false,
    };
  },
  computed: {
    imageUrlC() {
      return `${this.url}${this.imageUrl}`;
    },
  },
  methods: {
    onClick() {
      if (this.eventOn) this.$emit("onClick");
    },
  },
  watch: {
    imageUrl() {
      this.imageError = false;
    },
  },
};
</script>
<style scoped>
ion-avatar,
img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: white;
}

ion-avatar ion-icon {
  background-color: white;
  border-radius: 50%;
  padding: 4px;
  font-size: 18px;
  color: #666666;
  margin-left: 30px;
  margin-top: -15px;
}
</style>
