<template>
  <ion-page>
    <Header title="Lista de jogadores" showBackButton @onClick="onClose()" />
    <Loading v-if="loading" />
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Opções</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content
          style="display: flex; justify-content: space-between; padding: 10px;"
        >
          <ion-button color="danger" @click="onClose()">cancelar</ion-button>
          <ion-button :disabled="!changed" @click="saveList()"
            >Confirmar lista</ion-button
          >
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle
            style="width: 100%; display: flex; justify-content: space-between; align-items: center;"
            >Jogadores
            <ion-icon
              :icon="trash"
              style="font-size: 25px; margin-right: 3px;"
              @click="clearList()"
            ></ion-icon>
          </ion-card-subtitle>
        </ion-card-header>
        <ion-searchbar
          class="nickname-color"
          debounce="1000"
          placeholder="Buscar jogador"
          :value="searchValue"
          @ionChange="searchValue = $event.target.value"
        ></ion-searchbar>
        <ion-card-content>
          <ion-list>
            <div
              class="container-administration"
              v-for="item in allPlayersPremiumC.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <ion-item
                lines="none"
                style="disply: flex; border-bottom: 1px solid #d9d9d9;"
                @click="confirmePlayer(item)"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
                <ion-label>
                  <h2 class="nickname-color">{{ item.apelido }}</h2>
                </ion-label>

                <ion-icon
                  v-if="allConfirmedPlayersId.includes(item.cdId)"
                  :icon="checkmarkCircle"
                  color="success"
                ></ion-icon>
              </ion-item>
            </div>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="2000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "ConfirmationPlayers",
  mixins: [requestManager, computedManager],
  components: {
    IonToast,
    Header,
    Avatar,
    Loading,
  },
  data() {
    return {
      changed: false,
      loading: false,
      message: "",
      searchValue: "",
      isOpenToast: false,
      allConfirmedPlayersId: [],
    };
  },
  computed: {
    allPlayersPremium() {
      return this.$store.state.base.allPlayersPremium;
    },
    allPlayersPremiumC() {
      if (this.searchValue) {
        return this.allPlayersPremium.filter((p) => {
          return `${p.nome}${p.apelido}`
            .toLowerCase()
            .includes(this.searchValue.toLowerCase());
        });
      } else {
        return this.allPlayersPremium;
      }
    },
    allConfirmedPlayers() {
      return this.$store.state.base.allConfirmedPlayers;
    },
    teamList() {
      return this.$store.state.base.teamList;
    },
    alreadySelectedPlayerIds() {
      return this.teamList
        .map((p) => p.players)
        .flat()
        .map((e) => e.cdId);
    },
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removeShowModal", "ModalConfirmationPlayers");
    });
    if (this.allPlayersPremium.length == 0) await this.init();
    this.allConfirmedPlayersId = this.allConfirmedPlayers.map((e) => e.cdId);
  },
  methods: {
    onClose() {
      this.$store.commit("base/removeShowModal", "ModalConfirmationPlayers");
    },
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getAllPlayersPremium");
      if (typeof response != "string") {
        this.$store.commit("base/setAllPlayersPremium", response);
      } else {
        this.$store.commit("base/setAllPlayersPremium", []);
      }
      this.loading = false;
    },
    confirmePlayer(pItem) {
      if (this.allConfirmedPlayersId.includes(pItem.cdId)) {
        if (this.alreadySelectedPlayerIds.includes(pItem.cdId)) {
          this.message =
            "Jogador não pode ser removido pois está incluído em um time.";
          this.isOpenToast = true;
          return;
        }

        this.allConfirmedPlayersId = this.allConfirmedPlayersId.filter(
          (id) => id != pItem.cdId
        );
      } else {
        this.allConfirmedPlayersId = [
          ...this.allConfirmedPlayersId,
          pItem.cdId,
        ];
      }
      this.changed = true;
    },
    async saveList() {
      this.loading = true;
      const response = await this.genericRequest("common/saveConfirmedList", {
        allConfirmedPlayers: this.allConfirmedPlayersId,
      });
      if (response != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      } else {
        const allConfirmedPlayers = this.allPlayersPremium.filter((e) =>
          this.allConfirmedPlayersId.includes(e.cdId)
        );
        this.$store.commit("base/setAllConfirmedPlayers", allConfirmedPlayers);
        this.onClose();
      }
      this.loading = false;
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    async clearList() {
      this.allConfirmedPlayersId = this.allConfirmedPlayersId.filter((e) =>
        this.alreadySelectedPlayerIds.includes(e)
      );
      this.changed = true;

      if (this.allConfirmedPlayersId.length > 0) {
        this.message =
          "Um ou mais jogadores não podem ser removidos pois estão incluídos em um time.";
        this.isOpenToast = true;
      }
    },
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 25px;
}

ion-label {
  font-size: 18px;
}

ion-icon {
  font-size: 30px;
}

ion-searchbar {
  width: 100% !important;
  --background: white;
  --box-shadow: none !important;
  border-bottom: 1px solid #d9d9d9;
}

ion-list {
  padding: 0;
}

ion-card-content {
  padding: 0;
}

ion-list ion-item {
  padding-top: 0px;
}

ion-item {
  --background: white;
  --inner-padding-end: 0px;
  --padding-start: 0px;
  padding-left: 10px;
  padding-right: 10px;
  --highlight-height: none;
  width: 100%;
  display: flex;
}

ion-select {
  --color: red;
}

.container-administration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
}

.select-text {
  font-weight: bold !important;
}

.avatar {
  margin-right: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
