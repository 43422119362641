<template>
  <ion-page>
    <Header
      title="Informações da partida"
      showBackButton
      @onClick="onClose()"
    />
    <Loading v-if="loading" />
    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-card v-for="item in informationMatch.events" :key="item.cdId">
        <ion-card-header
          v-if="['FAL', 'GOL', 'ASS', 'GOLP', 'GOLC'].includes(item.eventType)"
        >
          <ion-card-title
            style="width: 100%; display: flex; justify-content: space-between;"
          >
            <div
              style="display: flex; justify-content: flex-start; align-items: center;"
            >
              <Avatar
                class="avatar"
                style="margin-right: 10px;"
                width="50"
                height="50"
                :imageUrl="item.imageUrl1"
                eventOn
              />
              <div>
                <h2 class="h2-information-match" style="width: 190px;">
                  {{ item.apelido1 }}
                </h2>
                <p
                  style="margin-top: 5px; font-size: 14px; width: 200px; font-weight: bold;"
                  :style="getColor(item.eventType)"
                >
                  {{ getEventName(item.apelido1, item.eventType) }}
                </p>
              </div>
            </div>
            <ion-icon
              v-if="
                accessLevel < 4 &&
                  (playerInformation?.cdId == informationMatch?.owner ||
                    (accessLevel == 1 && informationMatch?.owner))
              "
              :icon="settings"
              @click="setOpenActionSheet(true, item.cdId, item.eventType)"
            />
          </ion-card-title>
        </ion-card-header>
        <ion-card-content
          v-if="['FAL', 'GOL', 'ASS', 'GOLP', 'GOLC'].includes(item.eventType)"
        >
          <p>
            {{ item.comment }}
          </p>
          <p class="time">
            {{ getDateTimeFormatted(item.dateTime) }}
          </p>
        </ion-card-content>

        <ion-card-header
          v-if="['SUB'].includes(item.eventType)"
          style="width: 100%; display: flex; justify-content: space-between;"
        >
          <ion-card-title>Substituição</ion-card-title>
          <ion-icon
            v-if="
              accessLevel < 4 &&
                (playerInformation?.cdId == informationMatch?.owner ||
                  (accessLevel == 1 && informationMatch?.owner))
            "
            :icon="settings"
            @click="
              setOpenActionSheet(
                true,
                item.cdId,
                item.eventType,
                item.cdIdJogador1
              )
            "
          />
        </ion-card-header>
        <ion-card-content v-if="['SUB'].includes(item.eventType)">
          <div class="container-game-events">
            <div class="player-substitutions">
              <div
                style="display: flex; flex-direction: column; align-items: center;"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imageUrl1"
                  eventOn
                />
                <p style="margin-top: 10px;">{{ item.apelido1 }}</p>
              </div>
              <div
                style="display: flex; align-items: center; margin-top: 15px;"
              >
                <ion-icon color="danger" :icon="arrowDownOutline" />
                <ion-icon color="success" :icon="arrowUpOutline" />
              </div>
              <div
                style="display: flex; flex-direction: column; align-items: center;"
              >
                <Avatar
                  class="avatar"
                  width="50"
                  height="50"
                  :imageUrl="item.imageUrl2"
                />
                <p style="margin-top: 10px;">{{ item.apelido2 }}</p>
              </div>
            </div>
            <p class="time">
              {{ getDateTimeFormatted(item.dateTime) }}
            </p>
          </div>
        </ion-card-content>

        <ion-card-header
          v-if="['INI', 'FIM', 'PSA', 'REINI'].includes(item.eventType)"
        >
          <ion-card-title>Status da partida</ion-card-title>
        </ion-card-header>
        <ion-card-content
          v-if="['INI', 'FIM', 'PSA', 'REINI'].includes(item.eventType)"
        >
          <div class="container-game-events">
            <h2 class="start-end-match">
              {{ getEventName(item.apelido1, item.eventType) }}
            </h2>
            <p class="time">
              {{ getDateTimeFormatted(item.dateTime) }}
            </p>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-action-sheet
        :is-open="isOpenActionSheet"
        header="Opções"
        :buttons="buttons"
        @onDidDismiss="setOpenActionSheet(false)"
      >
      </ion-action-sheet>

      <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="5000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonActionSheet, IonContent, IonToast } from "@ionic/vue";
import Header from "@/components/Layout/Header.vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Loading from "@/components/Layout/Loading.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import { useBackButton } from "@ionic/vue";

export default {
  name: "ModalMatchInformation",
  mixins: [requestManager, computedManager],
  components: { IonActionSheet, IonContent, IonToast, Header, Avatar, Loading },
  props: {
    cdIdMatch: {
      type: Number,
      default: null,
    },
  },
  computed: {
    informationMatch() {
      return this.$store.state.base.informationMatch;
    },
  },
  data() {
    return {
      isOpenToast: false,
      isOpenActionSheet: false,
      cdIdEvent: null,
      eventType: null,
      cdIdJogador: null,
      message: "",
      buttons: [
        {
          text: "Deletar evento",
          role: "destructive",
          cssClass: "close",
          icon: this.trash,
          handler: () => {
            this.deleteEvent();
          },
        },
        {
          text: "Cancelar",
          icon: this.close,
          cssClass: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
    };
  },
  async mounted() {
    useBackButton(100, () => {
      this.$store.commit("base/removePropsModalCascate");
      this.$store.commit("base/removeShowModalCascate");
    });
    if (!this.cdIdMatch) {
      if (!this.informationMatch || !this.informationMatch.owner) {
        await this.init();
      }
    } else {
      if (
        !this.informationMatch ||
        !this.informationMatch.cdIdMatch ||
        this.informationMatch.cdIdMatch != this.cdIdMatch
      ) {
        await this.init();
      }
    }
  },
  methods: {
    onClose() {
      this.$store.commit("base/removePropsModal", "ModalMatchInformation");
      this.$store.commit("base/removeShowModal", "ModalMatchInformation");
    },
    async init() {
      this.loading = true;
      this.resetInformationMatch();
      const response = await this.genericRequest(
        "common/getAllMatchInformation",
        { cdIdMatch: this.cdIdMatch }
      );
      if (typeof response != "string") {
        this.$store.commit("base/setInformationMatch", response);
      } else {
        this.resetInformationMatch();
      }
      this.loading = false;
    },
    getColor(pClassName) {
      if (pClassName == "ASS") {
        return "color: #3880e6;";
      } else if (pClassName == "GOLP") {
        return "color: #5260ff;";
      } else if (pClassName == "GOL") {
        return "color: #2dd36f;";
      } else if (pClassName == "FAL") {
        return "color: #ffc409;";
      } else if (pClassName == "GOLC") {
        return "color: #eb445a;";
      }
    },
    getEventName(pPlayerName, pEventType) {
      if (pEventType == "INI") {
        return "Início da partida";
      } else if (pEventType == "GOL") {
        return "Fez um gol";
      } else if (pEventType == "GOLP") {
        return "Fez um gol de pênalti";
      } else if (pEventType == "GOLC") {
        return "Fez um gol contra";
      } else if (pEventType == "ASS") {
        return "Deu uma assistência";
      } else if (pEventType == "FAL") {
        return "Fez uma falta";
      } else if (pEventType == "PSA") {
        return "Partida pausada";
      } else if (pEventType == "REINI") {
        return "Partida reiniciada";
      } else if (pEventType == "FIM") {
        return "Fim da partida";
      }
    },
    getDateTimeFormatted(pDateTime) {
      var dateTime = new Date(pDateTime);

      return (
        dateTime.toLocaleTimeString("pt-BR") +
        " " +
        dateTime.toLocaleDateString("pt-BR")
      );
    },
    setOpenActionSheet(pState, pCdId, pEventType, pCdIdJogador) {
      if (pCdId) this.cdIdEvent = pCdId;
      if (pEventType) this.eventType = pEventType;
      if (pCdIdJogador) this.cdIdJogador = pCdIdJogador;
      this.isOpenActionSheet = pState;
    },
    resetInformationMatch() {
      this.$store.commit("base/setInformationMatch", {
        events: [],
        owner: null,
        cdIdMatch: null,
      });
    },
    async deleteEvent() {
      this.loading = true;
      const response = await this.genericRequest("common/deleteEvent", {
        cdIdEvent: this.cdIdEvent,
        eventType: this.eventType,
        cdIdMatch: this.informationMatch.cdIdMatch,
        cdIdJogador: this.cdIdJogador,
      });

      if (response != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      } else {
        await this.init();
        this.$store.commit("base/setReloadCurrentMatch", Date.now());
      }
      this.loading = false;
    },
    async doRefresh(event) {
      await this.init();
      event.target.complete();
    },
  },
};
</script>
<style scoped>
h2 {
  margin: 0;
  color: #000000cc;
  font-size: 1rem;
}

img {
  width: 60px;
  border-radius: 50%;
  border: 5px solid white;
  background-color: white;
}

ion-icon {
  font-size: 25px;
}

ion-card-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}

ion-card-title p {
  margin: 0;
  width: 100%;
}

ion-card-content {
  padding: 10px;
}

ion-card-content p {
  margin: 0;
}

.container-game-events {
  width: 100%;
  margin-top: 10px;
}

.player-substitutions {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 10px;
}

.player-substitutions p {
  text-align: center;
  width: 130px;
}

.start-end-match {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.time {
  text-align: right;
  margin-top: 15px;
  font-size: 12px;
  color: #666666;
}
</style>
