import { useRouter } from "vue-router";

export default {
  data() {
    return { router: useRouter() };
  },
  computed: {
    token() {
      return this.$store.state.profile.playerInformation.sessaoId;
    },
  },
  methods: {
    async genericRequest(pMethod, pParams = {}) {
      const object = pParams;
      object.token =
        this.token ||
        JSON.parse(localStorage.getItem("playerInformation")).sessaoId;
      try {
        const response = await this.$store.dispatch(pMethod, object);

        if (typeof response == "string") {
          if (response == "Usuário não logado") {
            localStorage.removeItem("playerInformation");
            this.router.replace("/Login");
            return null;
          } else {
            return response;
          }
        } else {
          return response;
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    },
  },
};
