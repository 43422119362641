export const calculateAge = (pYearBirth, pBirthMonth, pbirthDay) => {
  var d = new Date();
  let currentYear = d.getFullYear();
  let currentMonth = d.getMonth() + 1;
  let currentDay = d.getDate();
  let yearBirth = +pYearBirth;
  let birthMonth = +pBirthMonth;
  let birthDay = +pbirthDay;
  let age = currentYear - yearBirth;

  if (
    currentMonth < birthMonth ||
    (currentMonth == birthMonth && currentDay < birthDay)
  ) {
    age--;
  }
  return age <= 0 ? "" : age + " anos";
};
