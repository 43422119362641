<template>
  <ion-page>
    <Loading v-if="loading" />
    <ion-tabs v-if="playerInformation">
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="Dashboard" href="/tabs/Dashboard">
          <ion-icon :icon="home" :color="theme" />
          <ion-label>Dashboard</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="Ranking" href="/tabs/Ranking">
          <ion-icon :icon="barChart" :color="theme" />
          <ion-label>Ranking</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="Administracao" href="/tabs/Administracao">
          <ion-icon
            v-if="accessLevel < 3"
            :icon="readerOutline"
            :color="theme"
          />
          <ion-icon v-else :icon="people" :color="theme" />
          <ion-label v-if="accessLevel < 3">Administração</ion-label>
          <ion-label v-else>Jogadores</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="Perfil"
          href="/tabs/Perfil"
          @click="getAmountTrophies()"
        >
          <ion-icon :icon="personCircleOutline" :color="theme" />
          <ion-label>Perfil</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <transition name="pop" appear>
      <ModalMatchesList
        v-if="showModal.includes('ModalMatchesList')"
        class="modal"
        :selectedDay="getProps('ModalMatchesList')"
      ></ModalMatchesList>
    </transition>

    <transition name="pop" appear>
      <ModalCreateMatch
        v-if="showModal.includes('ModalCreateMatch')"
        class="modal"
      ></ModalCreateMatch>
    </transition>

    <transition name="pop" appear>
      <ModalCreateTeams
        v-if="showModal.includes('ModalCreateTeams')"
        class="modal"
      ></ModalCreateTeams>
    </transition>

    <transition name="pop" appear>
      <ModalConfirmationPlayers
        v-if="showModal.includes('ModalConfirmationPlayers')"
        class="modal"
      ></ModalConfirmationPlayers>
    </transition>

    <transition name="pop" appear>
      <ModalPlayerProfile
        v-if="showModal.includes('ModalPlayerProfile')"
        class="modal"
        :playerId="getProps('ModalPlayerProfile')"
      ></ModalPlayerProfile>
    </transition>

    <transition name="pop" appear>
      <ModalTrophies
        v-if="showModal.includes('ModalTrophies')"
        :playerId="getProps('ModalTrophies')"
        class="modal"
      ></ModalTrophies>
    </transition>

    <transition name="pop" appear>
      <ModalMatchInformation
        v-if="showModal.includes('ModalMatchInformation')"
        class="modal"
        :cdIdMatch="getProps('ModalMatchInformation')"
      ></ModalMatchInformation>
    </transition>

    <transition name="pop" appear>
      <ModalMatchManager
        v-if="showModal.includes('ModalMatchManager')"
        :matchSummary="getProps('ModalMatchManager')"
        class="modal"
      ></ModalMatchManager>
    </transition>

    <transition name="pop" appear>
      <ModalAchievements
        v-if="showModal.includes('ModalAchievements')"
        class="modal"
      ></ModalAchievements>
    </transition>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
} from "@ionic/vue";
import Loading from "@/components/Layout/Loading.vue";
import computedManager from "@/mixins/ComputedManager.js";
import ModalMatchesList from "@/components/Modals/ModalMatchesList.vue";
import ModalMatchInformation from "@/components/Modals/ModalMatchInformation.vue";
import ModalPlayerProfile from "@/components/Modals/ModalPlayerProfile.vue";
import ModalCreateMatch from "@/components/Modals/ModalCreateMatch.vue";
import ModalCreateTeams from "@/components/Modals/ModalCreateTeams.vue";
import ModalMatchManager from "@/components/Modals/ModalMatchManager.vue";
import ModalConfirmationPlayers from "@/components/Modals/ModalConfirmationPlayers.vue";
import ModalTrophies from "@/components/Modals/ModalTrophies.vue";
import ModalAchievements from "@/components/Modals/ModalAchievements.vue";
import requestManager from "@/mixins/RequestManager";
import { StatusBar } from "@ionic-native/status-bar";

export default {
  name: "Tabs",
  mixins: [computedManager, requestManager],
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    Loading,
    ModalMatchesList,
    ModalMatchInformation,
    ModalPlayerProfile,
    ModalCreateMatch,
    ModalCreateTeams,
    ModalMatchManager,
    ModalConfirmationPlayers,
    ModalTrophies,
    ModalAchievements,
  },
  computed: {
    showModal() {
      return this.$store.state.base.showModal;
    },
    propsModal() {
      return this.$store.state.base.propsModal;
    },
  },
  beforeMount() {
    const playerInformation = localStorage.getItem("playerInformation");
    if (playerInformation) {
      this.$store.commit(
        "profile/setPlayerInformation",
        JSON.parse(playerInformation)
      );
    } else {
      localStorage.removeItem("playerInformation");
      this.router.replace("/Login");
    }
    const theme = localStorage.getItem("theme");
    if (theme) {
      document.body.classList.toggle("dark", true);
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleLightContent();
    } else {
      StatusBar.backgroundColorByHexString("#f5f7f9");
      StatusBar.styleDefault();
    }
  },
  methods: {
    getProps(pName) {
      const object = this.propsModal.find((e) => e.name == pName);
      return object ? object.prop : null;
    },
    async getAmountTrophies() {
      const playerInformation = localStorage.getItem("playerInformation");
      if (playerInformation) {
        const response = await this.genericRequest("common/getAmountTrophies", {
          playerId: JSON.parse(playerInformation).cdId,
        });
        if (typeof response != "string") {
          this.$store.commit("base/setAmountTrophies", response);
        } else {
          this.$store.commit("base/setAmountTrophies", 0);
        }
      }
    },
  },
};
</script>

<style>
.modal {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100vh;
  padding-bottom: 50px;
  top: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1),
    opacity 0.2s linear;
}

.pop-enter-from,
.pop-leave-to {
  opacity: 0.5;
  transform: scale(1) translateY(100%);
}

ion-content {
  --ion-background-color: #f5f7f9;
}

ion-card {
  width: 90%;
  margin: auto;
  background-color: white;
  margin-top: 15px;
  margin-bottom: 15px;
}

ion-card-header {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #f3f3f3;
}

ion-card-title {
  color: #666666;
  font-size: 18px;
  margin: 0;
}

ion-card-content {
  padding: 10px;
}

ion-segment {
  margin: auto;
}

ion-searchbar {
  padding: 0 !important;
  margin: auto;
  --background: white;
}

ion-list ion-item {
  background-color: white;
}

ion-chip {
  padding: 0;
  padding-right: 10px;
  border-radius: 20px;
  height: 35px;
  --background: rgb(240, 240, 240);
  border: 1px solid #d9d9d9;
}

ion-segment-button {
  height: 40px;
  min-height: 40px;
  background-color: #f3f3f3;
  border-bottom: 1px solid #d9d9d9;
}

ion-fab-button {
  --box-shadow: none;
  --background: transparent;
  --color: rgb(235, 235, 235);
  width: 40px;
  height: 40px;
}

ion-tab-bar {
  --background: white;
  border-top: 1px solid #d9d9d9;
}

.h1-color {
  color: #454545;
}

.nickname-color {
  color: #000000cc;
}

#themeToggle {
  --background: #dbdbdb;
}

.custom-textarea ion-textarea,
.custom-textarea textarea {
  width: 100%;
  min-height: 50px;
  margin: 0;
  padding: 10px;
}

.end-game ion-icon {
  color: #42d77d !important;
}

.close ion-icon {
  color: #ea4335 !important;
}

body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1a1b1e;
}

body.dark ion-card-header {
  border-bottom: 1px solid #525252;
  background-color: black !important;
}

body.dark ion-content {
  --ion-background-color: #1a1b1e;
}

body.dark .p-ranking,
body.dark ion-toolbar .custom-ion-toolbar,
body.dark .h2-information-match,
body.dark ion-card-title,
body.dark ion-label,
body.dark .alert-radio-label,
body.dark .alert-title,
body.dark .action-sheet-button-inner,
body.dark ion-button,
body.dark .time,
body.dark .h1-color,
body.dark .picker-opt,
body.dark .nickname-color {
  color: #bebebe !important;
}

body.dark .picker-opt-selected {
  color: #3880ff !important;
}

body.dark ion-tab-bar {
  --background: #1f1f1f;
  border-top: 1px solid #1f1f1f;
}

body.dark ion-list,
body.dark ion-list ion-item,
body.dark .loading-ranking,
body.dark ion-card-content,
body.dark .custom-ion-card,
body.dark .current-football-match-card,
body.dark .p-color {
  background-color: #272930 !important;
}

body.dark .logo {
  background-color: #bebebe;
  border-radius: 10px;
}

body.dark .refresher-refreshing-icon {
  background-color: #272930 !important;
  border-color: black !important;
}

body.dark ion-toolbar {
  --background: black !important;
}

body.dark .avatar,
body.dark .avatar-list {
  border-color: #40424a;
}

body.dark ion-avatar ion-icon {
  background-color: #40424a;
  color: #bebebe;
}

body.dark .banner {
  background-color: #8d8d8d !important;
}

body.dark ion-searchbar {
  width: 100% !important;
  --background: #272930 !important;
  --box-shadow: none !important;
  border-bottom: 1px solid #525252 !important;
}

body.dark .highlights-information ion-card {
  border: 1px solid #525252 !important;
}

body.dark ion-item {
  --background: #272930;
  border-bottom: 1px solid #525252 !important;
}

body.dark ion-chip {
  --background: #1a1b1e;
  border: 1px solid #525252;
}

body.dark ion-card {
  background-color: #40424a !important;
}

body.dark .h1-ranking {
  background-color: #272930 !important;
  color: #bebebe;
}

body.dark ion-segment-button {
  background-color: #272930 !important;
  border-bottom: 1px solid #525252;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
</style>
